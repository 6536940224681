import React, { useState } from "react";
import "./login.css";
import clipart from "../../images/loginClipart.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { height, styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import { useMobile } from "../../contexts/Mobile";
import useApiUrl from "../../common/apiUrl";

const MyTextField = styled(TextField)({
  width: "20rem",
  borderRadius: "2rem",
});

export default function Login() {
  const navigate = useNavigate();
  const domainUrl = useApiUrl();

  // Local state for input values and error handling
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth(); // Use the login function from AuthContext
  const isMobile = useMobile(); // Get the mobile state
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  // Handle login
  // const handleLogin = async () => {
  //   // Basic validation for empty fields
  //   if (!username || !password) {
  //     setError("يرجى تعبئة جميع الحقول");
  //     return;
  //   }

  //   try {
  //     // Send login request using GET with query parameters
  //     const response = await axios.get(
  //       `https://cloud.smart-donate.com/backend/api/login.php`,
  //       {
  //         params: {
  //           username: username,
  //           password: password,
  //         },
  //       }
  //     );

  //     // Assuming success if response is ok, redirect to home page
  //     if (response.data.username === username) {
  //       navigate("/home", {
  //         state: {
  //           username: username,
  //           password: password,
  //         },
  //       });
  //       localStorage.setItem("username", username);
  //       localStorage.setItem("password", password);
  //     } else {
  //       setError("بيانات تسجيل الدخول غير صحيحة");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     setError("حدث خطأ أثناء تسجيل الدخول");
  //   }
  // };
  const handleLogin = async () => {
    const apiUrl = `${domainUrl}/backend/api/login.php`;
    const data = new URLSearchParams();
    data.append("username", username);
    data.append("password", password);

    try {
      // Send a POST request to the API
      const response = await axios.post(apiUrl, data);

      // Check if the response is 0 (failed login) or contains data (successful login)
      if (response.data === 0) {
        // Invalid login credentials
        setUsernameError(true);
        setPasswordError(true);
        setUsernameErrorMessage("البريد الإلكتروني أو الرمز السري غير صحيح");
        setPasswordErrorMessage("البريد الإلكتروني أو الرمز السري غير صحيح");
      } else if (response.data) {
        // Successful login
        login(response, username, password);
        navigate("/dashboard");
      }
    } catch (error) {
      // Handle other errors (network, server, etc.)
      setUsernameError(true);
      setPasswordError(true);
      setUsernameErrorMessage("حدث خطأ في الاتصال. حاول مرة أخرى.");
      setPasswordErrorMessage("حدث خطأ في الاتصال. حاول مرة أخرى.");
      console.error("Login error:", error);
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="loginMobileContainer">
          <div
            style={{
              gridRow: "2/3",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <img
              src={clipart}
              alt="Login Clipart"
              style={{ width: "40vw", height: "auto" }}
            />
          </div>
          <div className="loginMobileForm" style={{ gridRow: "3/4" }}>
            <Typography
              variant="h3"
              style={{
                fontFamily: "Poppins",
                margin: "0",
                padding: "0",
                textAlign: "start",
              }}
            >
              مرحبا من جديد
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                fontFamily: "Poppins",
                marginBottom: "3rem",
                padding: "0",
              }}
            >
              رجاء أدخل بياناتك
            </Typography>

            <div className="formMobileFields">
              <Typography
                variant="subtitle1"
                style={{ fontFamily: "Poppins", margin: 0, padding: 0 }}
              >
                الإيميل
              </Typography>
              <MyTextField
                id="outlined-basic1"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
                error={usernameError} // Show error state if login fails
                helperText={usernameError ? usernameErrorMessage : ""} // Display the error message
              />

              <Typography
                variant="subtitle1"
                style={{ fontFamily: "Poppins", padding: 0 }}
              >
                الرمز السري
              </Typography>
              <MyTextField
                id="outlined-basic2"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                error={passwordError} // Show error state if login fails
                helperText={passwordError ? passwordErrorMessage : ""} // Display the error message
              />

              {/* Display error message if any */}
              {error && (
                <Typography
                  variant="body2"
                  style={{ color: "red", marginTop: "1rem" }}
                >
                  {error}
                </Typography>
              )}

              <Button
                variant="contained"
                style={{
                  width: "20rem",
                  backgroundColor: "#352D66",
                  height: "3.5rem",
                  marginTop: "2rem",
                  borderRadius: "0.25rem",
                }}
                onClick={handleLogin}
              >
                تسجيل الدخول
              </Button>
              <Typography
                variant="subtitle1"
                style={{
                  fontFamily: "Poppins",
                  margin: 0,
                  padding: 0,
                  color: "#352D66",
                }}
              >
                نسيت كلمة المرور؟
              </Typography>
            </div>
          </div>
        </div>
      ) : (
        <div className="loginContainer">
          <div className="loginClipart">
            <img src={clipart} alt="Login Clipart" />
          </div>
          <div className="loginForm">
            <Typography
              variant="h3"
              style={{ fontFamily: "Poppins", margin: "0", padding: "0" }}
            >
              مرحبا من جديد
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                fontFamily: "Poppins",
                marginBottom: "3rem",
                padding: "0",
              }}
            >
              رجاء أدخل بياناتك
            </Typography>

            <div className="formFields">
              <Typography
                variant="subtitle1"
                style={{ fontFamily: "Poppins", margin: 0, padding: 0 }}
              >
                الإيميل
              </Typography>
              <MyTextField
                id="outlined-basic1"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
                error={usernameError} // Show error state if login fails
                helperText={usernameError ? usernameErrorMessage : ""} // Display the error message
              />
              <Typography
                variant="subtitle1"
                style={{ fontFamily: "Poppins", padding: 0 }}
              >
                الرمز السري
              </Typography>
              <MyTextField
                id="outlined-basic2"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                error={passwordError} // Show error state if login fails
                helperText={passwordError ? passwordErrorMessage : ""} // Display the error message
              />

              {/* Display error message if any */}
              {error && (
                <Typography
                  variant="body2"
                  style={{ color: "red", marginTop: "1rem" }}
                >
                  {error}
                </Typography>
              )}

              <Button
                variant="contained"
                style={{
                  width: "20rem",
                  backgroundColor: "#352D66",
                  height: "3.5rem",
                  marginTop: "2rem",
                  borderRadius: "0.25rem",
                }}
                onClick={handleLogin}
              >
                تسجيل الدخول
              </Button>
              <Typography
                variant="subtitle1"
                style={{
                  fontFamily: "Poppins",
                  margin: 0,
                  padding: 0,
                  color: "#352D66",
                }}
              >
                نسيت كلمة المرور؟
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
